<template>
  <div class="card o-hidden border-0 shadow-lg my-5">
    <div class="card-body p-0">
      <!-- Nested Row within Card Body -->
      <div class="row">
        <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
        <div class="col-lg-7">
          <div class="p-5">
            <div class="text-center">
              <h1 class="h4 text-gray-900 mb-4">Регистрация аккаунта!</h1>
            </div>
            <form @submit="Create" class="user">
              <div class="form-group">
                <input v-model="user.name" :class="{ 'is-invalid': error.name}" @input="this.error = {}" type="text" class="form-control form-control-user" placeholder="ФИО"
                       autocomplete="on" required>
                <div v-if="error.name" class="invalid-feedback">
                  <span v-for="error in error.name">{{ error }}</span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <input v-model="user.email" :class="{ 'is-invalid': error.email}" @input="this.error = {}" type="email" class="form-control form-control-user"
                         placeholder="Email адрес" autocomplete="on" required>
                  <div v-if="error.email" class="invalid-feedback">
                    <span v-for="error in error.email">{{ error }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <input v-phone v-model="user.phone" :class="{ 'is-invalid': error.phone}" @input="this.error = {}" type="text" class="form-control form-control-user"
                         placeholder="Телефон" autocomplete="on" required>
                  <div v-if="error.phone" class="invalid-feedback">
                    <span v-for="error in error.phone">{{ error }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <input v-model="user.password" :class="{ 'is-invalid': error.password}" @input="this.error = {}" type="password" class="form-control form-control-user"
                         placeholder="Password" autocomplete="on" required>
                  <div v-if="error.password" class="invalid-feedback">
                    <span v-for="error in error.password">{{ error }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <input v-model="user.re_password" :class="{ 'is-invalid': error.re_password}" @input="this.error = {}" type="password" class="form-control form-control-user"
                         placeholder="Repeat Password" autocomplete="on" required>
                  <div v-if="error.re_password" class="invalid-feedback">
                    <span v-for="error in error.re_password">{{ error }}</span>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary btn-user btn-block">
                Зарегистрировать
              </button>
              <!--
              <hr>
              <a href="index.html" class="btn btn-google btn-user btn-block">
                <i class="fab fa-google fa-fw"></i> Register with Google
              </a>
              <a href="index.html" class="btn btn-facebook btn-user btn-block">
                <i class="fab fa-facebook-f fa-fw"></i> Register with Facebook
              </a>
              -->
            </form>
            <hr>
            <div class="text-center">
              <router-link class="small" to="/forgot">Забыли пароль?</router-link>
            </div>
            <div class="text-center">
              <router-link class="small" to="login">Уже есть аккаунт? Авторизуйтесь!</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import Loader from "../../components/static/Loader";

export default {
  name: "CreatePage",
  mounted() {

    this.$store.commit('SET_LOADER_VISIBLE', false);
  },
  components: {Loader},
  data() {
    return {
      user: this.$store.getters.GET_DEBUG ? {
            name: 'Руслан',
            email: '49198@bk.ru',
            phone: '89037786666',
            password: '418716Rus',
            re_password: '418716Rus',
          }
          :
          {

            name: '',
            email: '',
            phone: '',
            password: '',
            re_password: '',
          },
      error: {}
    }
  },
  methods: {
    Create: async function (e) {
      e.preventDefault()
      this.error = '';
      try {
        //console.log(this.user)
        await this.$store.dispatch('REGISTER', this.user);
        await this.$store.dispatch('LOGIN', this.user);
        await router.push('home');
      } catch (error) {
        console.log(error)
        this.error = error;
      }
    }
  }
}
</script>
<style scoped></style>
